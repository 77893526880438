import { IconEye } from "@tabler/icons-react";
import CoolingPeriod from "components/coolingPeriod";
import ChakraUIMenu from "components/menu";
import UpdateKYC from "components/updateKYC";
import Layout from "layout";
import React, { useState } from "react";
import toast from "react-hot-toast";
import {
  useAdminVerifyAndUpgradeKYC,
  useGetKycHistory,
  useGetKycVerificationsRecords,
} from "utils/api/auth.api";
import ManageNotesDocs from "views/manageNotesDocs";

const kycTypes = ["All", "Kyc1", "Kyc2"];

const Kyc = () => {
  const [selectedKycType, setSelectedKycType] = useState("All");

  const [activeTab, setActiveTab] = useState<string>("KYC");

  const { data } = useGetKycVerificationsRecords();
  const { data: kycHistory } = useGetKycHistory();
  console.log(kycHistory);
  const verifyAndUpgradeKYCMutation = useAdminVerifyAndUpgradeKYC();

  const filteredData =
    kycTypes.includes(selectedKycType) && selectedKycType !== "All"
      ? data?.filter(
          (item: any) =>
            item?.kycLevel?.toString() === selectedKycType.slice(-1)
        )
      : data;

  const filteredData2 =
    kycTypes.includes(selectedKycType) && selectedKycType !== "All"
      ? kycHistory?.filter(
          (item: any) =>
            item?.kycLevel?.toString() === selectedKycType.slice(-1)
        )
      : kycHistory;

  return (
    <Layout>
      <div className="dashboard-main">
        <div className="main-wrapper max-w-5xl p-6">
          <ul className="flex flex-wrap items-center gap-3 mb-4">
            <li>
              <div
                onClick={() => setActiveTab("KYC")}
                className={` ${
                  activeTab === "KYC" ? "text-black-900" : "text-black-900/20"
                } text-xl cursor-pointer`}
              >
                KYC
              </div>
            </li>
            <li>
              <div
                onClick={() => setActiveTab("History")}
                className={` ${
                  activeTab === "History"
                    ? "text-black-900"
                    : "text-black-900/20"
                } text-xl cursor-pointer`}
              >
                History
              </div>
            </li>
            <li>
              <div
                onClick={() => setActiveTab("Settings")}
                className={` ${
                  activeTab === "Settings"
                    ? "text-black-900"
                    : "text-black-900/20"
                } text-xl cursor-pointer`}
              >
                Settings
              </div>
            </li>
          </ul>
          {activeTab === "KYC" ? (
            <>
              <div className="flex items-center justify-between mb-1 mt-4">
                <div className="">
                  <label htmlFor="filter" className="mr-2 text-lg">
                    Filter
                  </label>
                  <select
                    id="filter"
                    className="text-black-700 text-sm rounded-lg border border-black-800 focus:outline-0 py-1.5 px-3"
                    value={selectedKycType}
                    onChange={(e) => setSelectedKycType(e.target.value)}
                  >
                    {kycTypes.map((type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="overflow-auto mt-4">
                <div className="w-[976px] h-[85dvh]">
                  <div className="table-wrapper max-h-[38rem] overflow-auto">
                    <table className="w-full">
                      <thead>
                        <tr>
                          <th className="rounded-l-lg border border-black-800/30 border-r-0 py-5 px-5 flex justify-start">
                            Time
                          </th>
                          <th className="py-5 px-5 border-y border-black-800/30 text-left">
                            Name
                          </th>
                          <th className="py-5 px-5 border-y border-black-800/30 text-left">
                            Email
                          </th>
                          <th className="py-5 px-5 border-y border-black-800/30 text-left">
                            Status
                          </th>
                          <th className="py-5 px-5 border-y border-black-800/30 text-left">
                            Level
                          </th>
                          <th className="rounded-r-lg border border-black-800/30 border-l-0 py-5 px-16 text-center">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredData?.map((data, idx) => (
                          <>
                            {/* {data?.kycStatus !== "Rejected" && ( */}
                            <TableRow
                              key={data?.userId}
                              data={data}
                              index={idx}
                              verifyAndUpgrade={verifyAndUpgradeKYCMutation}
                              noAction={false}
                              activeTab={activeTab}
                            />
                            {/* )} */}
                          </>
                        ))}
                        {/* )} */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </>
          ) : activeTab === "Settings" ? (
            <ManageNotesDocs />
          ) : (
            <>
              <div className="flex items-center justify-between mb-1 mt-4">
                <div className="">
                  <label htmlFor="filter" className="mr-2 text-lg">
                    Filter
                  </label>
                  <select
                    id="filter"
                    className="text-black-700 text-sm rounded-lg border border-black-800 focus:outline-0 py-1.5 px-3"
                    value={selectedKycType}
                    onChange={(e) => setSelectedKycType(e.target.value)}
                  >
                    {kycTypes.map((type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="overflow-auto mt-4">
                <div className="w-[976px] h-[85dvh]">
                  <div className="table-wrapper max-h-[38rem] overflow-auto">
                    <table className="w-full">
                      <thead>
                        <tr>
                          <th className="rounded-l-lg border border-black-800/30 border-r-0 py-5 px-5 flex justify-start">
                            Time
                          </th>
                          <th className="py-5 px-5 border-y border-black-800/30 text-left">
                            Name
                          </th>
                          <th className="py-5 px-5 border-y border-black-800/30 text-left">
                            Email
                          </th>
                          <th className="py-5 px-5 border-y border-black-800/30 text-left">
                            Status
                          </th>
                          <th className="py-5 px-5 border-y border-black-800/30 text-left">
                            Reason
                          </th>
                          <th className="py-5 px-5 border-y border-black-800/30 text-left">
                            Level
                          </th>
                          <th className="rounded-r-lg border border-black-800/30 border-l-0 py-5 px-16 text-center">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredData2?.map((data, idx) => (
                          <>
                            {/* {data?.kycStatus !== "Rejected" && ( */}
                            <TableRow
                              key={data?.userId}
                              data={data}
                              index={idx}
                              noAction={true}
                              verifyAndUpgrade={verifyAndUpgradeKYCMutation}
                              activeTab={activeTab}
                            />
                            {/* )} */}
                          </>
                        ))}
                        {/* )} */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Kyc;

function TableRow({
  data,
  index,
  verifyAndUpgrade,
  noAction,
  activeTab,
}: {
  data: any;
  index: number;
  verifyAndUpgrade: any;
  noAction: any;
  activeTab: string;
}) {
  const [isVisible, setIsVisible] = useState(false); // Change initial state to `false`
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  // const [isRejectOpen, setIsRejectOpen] = useState(false);
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);
  const [coolingPeriod, setCoolingPeriod] = useState(0);
  const [message, setMessage] = useState("");
  const [isHovered, setIsHovered] = useState(-1);
  const handleClick = async (option: string) => {
    try {
      if (option === "Approve") {
        const formData = {
          recordId: data?.id,
          kycStatus: "Approved",
          kycLevel: Number(data?.kycLevel),
          coolingPeriod: 0,
          comment: "",
        };
        console.log(formData);
        await verifyAndUpgrade.mutateAsync(formData);
        toast.success("Kyc Approved Successfully!!!");
      } else if (option === "Reject") {
        if (data?.kycLevel === "1") {
          setIsCreateOpen(true);
        } else {
          setIsCreateOpen(true);
          // const formData = {
          //   recordId: data?.id,
          //   kycStatus: "Rejected",
          //   kycLevel: Number(data?.kycLevel),
          //   coolingPeriod: 0,
          //   comment: "",
          // };
          // await verifyAndUpgrade.mutateAsync(formData);
          // toast.success("Kyc Rejected Successfully!!!");
        }
      } else if (option === "Request for Update") {
        // setIsUpdateOpen(true);
        const formData = {
          recordId: data?.id,
          kycStatus: "Update",
          kycLevel: Number(data?.kycLevel),
          coolingPeriod: 0,
          comment: "",
        };
        await verifyAndUpgrade.mutateAsync(formData);
        toast.success("Request for Update Successful!!!");
      }
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  const handleUpdateSubmit = async () => {
    try {
      const formData = {
        recordId: data?.id,
        kycStatus: "Update",
        kycLevel: Number(data?.kycLevel),
        coolingPeriod: 0,
        comment: message,
      };
      await verifyAndUpgrade.mutateAsync(formData);
      toast.success("Kyc Requested for Update!!!");
      setMessage("");
      setIsUpdateOpen(false);
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  const handleSubmit = async () => {
    try {
      const formData = {
        recordId: data?.id,
        kycStatus: "Rejected",
        kycLevel: Number(data?.kycLevel),
        coolingPeriod,
        comment: message,
      };
      await verifyAndUpgrade.mutateAsync(formData);
      toast.success("Kyc Rejected Successfully!!!");
      setCoolingPeriod(0);

      setIsCreateOpen(false);
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  const menuOptions = ["Approve", "Reject"];
  if (data?.kycLevel === "1") {
    menuOptions.push("Request for Update");
  }

  return (
    <>
      <tr className={"bg-[#FFDEEA] "}>
        <td className="rounded-l-lg px-2 border border-black-800/30 border-r-0 py-5">
          <p className="text-black-900 text-xs ml-3">
            {new Date(data?.updatedAt).toLocaleDateString("us", {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </p>
        </td>
        <td className="py-5 px-5 border-y border-black-800/30 text-left">
          <p className="text-black-900 text-xs font-semibold">{data?.name}</p>
        </td>
        <td className="py-5 px-5 border-y border-black-800/30">
          <p className="text-black-900 text-xs font-semibold">{data?.email}</p>
        </td>

        <td className="py-5 px-5 border-y border-black-800/30">
          <p className="text-black-900 text-xs font-semibold">
            {data?.kycStatus}
          </p>
        </td>
        {activeTab === "History" && (
          <td className="py-5 px-5 border-y border-black-800/30 ">
            <p className="text-black-900 text-xs font-semibold">
              {data?.reason}
            </p>
          </td>
        )}
        <td className="py-5 px-5 border-y border-black-800/30">
          <p className="text-black-900 text-sm text-center flex gap-2">
            Level {data?.kycLevel}
          </p>
        </td>

        <td className="rounded-r-lg px-0 border border-black-800/30 border-l-0  ">
          <div className="flex gap-4 justify-center">
            {/* <i
              className="fa fa-eye text-red hover:text-[19px]"
              style={{ cursor: "pointer", color: "red" }}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
            ></i> */}
            <IconEye
              cursor={"pointer"}
              onClick={() => setIsVisible(!isVisible)}
            />
            {!noAction && (
              <ChakraUIMenu options={menuOptions} onClick={handleClick} />
            )}
          </div>
        </td>
        <CoolingPeriod
          isOpen={isCreateOpen}
          setIsOpen={setIsCreateOpen}
          setCoolingPeriod={setCoolingPeriod}
          coolingPeriod={coolingPeriod}
          kycLevel={data?.kycLevel}
          onSubmit={handleSubmit}
          message={message}
          setMessage={setMessage}
          verifyAndUpgradeKYCMutation={verifyAndUpgrade}
        />
        <UpdateKYC
          isOpen={isUpdateOpen}
          setIsOpen={setIsUpdateOpen}
          kycLevel={data?.kycLevel}
          onSubmit={handleUpdateSubmit}
          message={message}
          setMessage={setMessage}
          verifyAndUpgradeKYCMutation={verifyAndUpgrade}
        />
      </tr>
      {isVisible && data?.kycLevel === "1" && (
        <tr className="bg-cyan-300 ">
          <td colSpan={7} className=" px-2 rounded-r-lg">
            <div className="px-3 pt-6" key={index}>
              <h2 className="text-lg font-bold mb-4">User Details</h2>
              <div className="flex items-center justify-between mb-5">
                <div className="flex gap-2 items-center">
                  {/* <img
                    src="Portrait-Placeholder.jpg"
                    className="h-[30px] rounded-[20px]"
                  /> */}
                  <p>{data?.name}</p>
                </div>
                <p>{data?.email}</p>
                <p>{data?.phone || "N/A"}</p>
                <p>{data?.address || "N/A"}</p>
                <p>{data?.country || "N/A"}</p>
              </div>
              {data.kycLevel === "1" && (
                <>
                  <div className="flex items-center gap-3 mb-4">
                    <h2 className="text-lg font-bold ">Document Type:</h2>
                    <p className="font-semibold">{data?.meansOfId || "N/A"}</p>
                  </div>
                  <div
                    className="flex justify-center gap-4"
                    // style={{
                    //   maxHeight: "450px",
                    //   maxWidth: "450px",
                    //   marginLeft: "auto",
                    //   marginRight: "auto",
                    // }}
                  >
                    <div>
                      <p className="text-lg">Front:</p>

                      <img
                        src={
                          data?.frontSidePictureUrl &&
                          (data?.frontSidePictureUrl === "" ||
                            data?.frontSidePictureUrl === "string")
                            ? "https://cdn.pixabay.com/photo/2015/12/01/20/28/road-1072823_640.jpg"
                            : data?.frontSidePictureUrl
                        }
                        className="my-2 rounded-xl cursor-pointer"
                        alt="document"
                      />
                    </div>
                    <div>
                      <p className="text-lg">Back:</p>

                      <img
                        src={data?.backSidePictureUrl}
                        className="my-2 rounded-xl cursor-pointer"
                        alt="document"
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </td>
        </tr>
      )}
      {isVisible && data?.kycLevel !== "1" && (
        <tr className="bg-cyan-300 ">
          <td colSpan={7} className=" px-2 rounded-r-lg">
            <div className="px-3 pt-6" key={index}>
              <h2 className="text-lg font-bold mb-4">Proof of Documents</h2>
              <div className="mx-4 flex flex-wrap gap-2 mb-[20px]">
                {data?.proofDocuments?.map((doc: any, docIdx: any) => (
                  <a
                    key={docIdx}
                    href={doc}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mx-auto cursor-pointer relative"
                    onMouseEnter={() => setIsHovered(docIdx)}
                    onMouseLeave={() => setIsHovered(-1)}
                  >
                    <img
                      src={doc}
                      alt="proof of document"
                      className={`h-[300px]`}
                    />
                    {isHovered === docIdx && (
                      <IconEye
                        // onMouseEnter={() => setIsHovered(docIdx)}
                        // onMouseLeave={() => setIsHovered(-1)}
                        size="50px"
                        className="absolute z-[2] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                      />
                    )}
                  </a>
                ))}
              </div>
            </div>
          </td>
        </tr>
      )}
    </>
  );
}
