import CreateCoin from "components/createCoin";
import EditCoin from "components/editCoin";
import Layout from "layout";
import React, { useState } from "react";
import { useAddCoin, useGetNetworks, useGetReserves } from "utils/api/user.api";
// import { Link } from "react-router-dom";
// import { useGetTransactions } from "utils/api/user.api";
// import { type UserTransaction } from "utils/types/user.type";

// const stableCoins = [
//   {
//     id: 1,
//     date: "09/19/2023, 04:35 PM",
//     name: "TRX",
//     symbol: "TRC20",
//     logo: "https://cryptologos.cc/logos/tron-trx-logo.svg?v=029",
//     address: "TWynZsd1MJjxPzgpYPas3exi3Zo6d3DLV",
//     coinGeckoId: "36575747",
//     swappedOn: true,
//     stableCoin: true,
//     withdrawn: ["cash", "bank"],
//     availableCurrencies: "BTC / Eth / USDT / TRX",
//   },
//   {
//     id: 2,
//     date: "09/19/2023, 04:35 PM",
//     name: "Eth",
//     symbol: "ERC20",
//     logo: "https://cryptologos.cc/logos/ethereum-eth-logo.png?v=029",
//     address: "TWynZsd1MJjxPzgpYPas3exi3Zo6d3DLV",
//     coinGeckoId: "36575747",
//     swappedOn: false,
//     stableCoin: true,
//     withdrawn: ["cash", "money"],
//     availableCurrencies: "BTC / USDT / TRX",
//   },
//   {
//     id: 3,
//     date: "09/19/2023, 04:35 PM",
//     name: "Bitcoin",
//     symbol: "BTC",
//     logo: "https://cryptologos.cc/logos/bitcoin-btc-logo.png?v=029",
//     address: "TWynZsd1MJjxPzgpYPas3exi3Zo6d3DLV",
//     coinGeckoId: "36575747",
//     swappedOn: false,
//     stableCoin: false,
//     withdrawn: [],
//     availableCurrencies: "USDT / TRX",
//   },
//   {
//     id: 4,
//     date: "09/19/2023, 04:35 PM",
//     name: "Matic",
//     symbol: "Polygon",
//     logo: "https://cryptologos.cc/logos/polygon-matic-logo.svg?v=029",
//     address: "TWynZsd1MJjxPzgpYPas3exi3Zo6d3DLV",
//     coinGeckoId: "36575747",
//     swappedOn: true,
//     stableCoin: false,
//     withdrawn: [],
//     availableCurrencies: "BTC / Eth",
//   },
//   {
//     id: 5,
//     date: "09/19/2023, 04:35 PM",
//     name: "Bitcoin",
//     symbol: "BTC",
//     logo: "https://cryptologos.cc/logos/bitcoin-btc-logo.png?v=029",
//     address: "TWynZsd1MJjxPzgpYPas3exi3Zo6d3DLV",
//     coinGeckoId: "36575747",
//     swappedOn: true,
//     stableCoin: false,
//     withdrawn: [],
//     availableCurrencies: "USDT / TRX",
//   },
// ];
export default function Coins() {
  const FiatCoins = [
    {
      id: 1,
      date: "09/19/2023, 04:35 PM",
      name: "PKR",
      currency: "PKR",
      withdrawn: ["Cash", "Bank"],
      availableCurrencies: ["USD", "AED"],
    },
    {
      id: 2,
      date: "09/19/2023, 04:35 PM",
      name: "USD",
      currency: "USD",
      withdrawn: ["Cash"],
      availableCurrencies: ["AUD", "PKR"],
    },
    {
      id: 3,
      date: "09/19/2023, 04:35 PM",
      name: "AED",
      currency: "AED",
      withdrawn: ["Mobile Money"],
      availableCurrencies: ["PKR", "USD"],
    },
    {
      id: 4,
      date: "09/19/2023, 04:35 PM",
      name: "PKR",
      currency: "PKR",
      withdrawn: ["Bank"],
      availableCurrencies: ["USD", "AED"],
    },
  ];

  const { data: coinsData } = useGetReserves();
  const addCoinMutation = useAddCoin();
  const { data: networksData } = useGetNetworks();

  const [activeTab, setActiveTab] = useState("coins");
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  console.log(coinsData);

  return (
    <Layout>
      <div className="dashboard-main">
        <div className="main-wrapper max-w-5xl p-6">
          <ul className="flex flex-wrap items-center gap-3 mb-4">
            <li>
              <div
                onClick={() => setActiveTab("coins")}
                className={` ${
                  activeTab === "coins" ? "text-black-900" : "text-black-900/20"
                } text-xl cursor-pointer`}
              >
                Crypto Coin/Token{" "}
              </div>
            </li>
            {/* <li>
              <div
                onClick={() => setActiveTab("fiat")}
                className={` ${
                  activeTab === "fiat" ? "text-black-900" : "text-black-900/20"
                } text-xl cursor-pointer`}
              >
                Fiat Currencies
              </div>
            </li> */}
          </ul>
          <div className="text-right">
            <button
              onClick={() => setIsCreateOpen(true)}
              className="hover:bg-cyan-800 bg-cyan-300 border border-cyan-300 rounded-lg text-black-700 text-base font-bold transition px-8 lg:px-16 py-3"
            >
              Create
            </button>
          </div>
          <input
            type="text"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder="Search by coin name"
            className="w-full text-black-700 text-sm rounded-lg border border-black-800 focus:outline-0 py-3.5 px-5 mt-4"
          />

          <div className="table-wrapper max-h-[38rem] overflow-auto ">
            {activeTab === "coins" && (
              <table className="w-full">
                <thead>
                  <tr>
                    <th className="rounded-l-lg border border-black-800/30 border-r-0 py-5 px-5 flex justify-start">
                      Symbol
                    </th>
                    <th className="py-5 px-5 border-y border-black-800/30 text-left">
                      Name
                    </th>
                    <th className="py-5 px-5 border-y border-black-800/30 text-left">
                      Application Balance
                    </th>
                    <th className="py-5 px-5 border-y border-black-800/30 text-left">
                      Exchange Balance
                    </th>
                    <th className="rounded-r-lg border border-black-800/30 border-l-0 py-5 px-16 text-left">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {coinsData
                    ?.filter(
                      (item: any) => item?.coin?.name?.includes(searchValue)
                    )
                    ?.map((transaction: any) => (
                      <CryptoTableRow
                        key={transaction?.id}
                        transaction={transaction}
                      />
                    ))}
                </tbody>
              </table>
            )}

            {activeTab === "fiat" && (
              <table className="w-full">
                <thead>
                  <tr>
                    <th className="rounded-l-lg border border-black-800/30 border-r-0 py-5 px-5 flex justify-start">
                      Date
                    </th>
                    <th className="py-5 px-5 border-y border-black-800/30 text-left">
                      Currency
                    </th>
                    <th className="py-5 px-5 border-y border-black-800/30 text-left">
                      Method
                    </th>
                    <th className="rounded-r-lg border border-black-800/30 border-l-0 py-5 px-16 text-center">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {FiatCoins?.filter(
                    (item: any) => item.name?.includes(searchValue)
                  )?.map((transaction: any) => (
                    <FiatTableRow
                      key={transaction?.id}
                      transaction={transaction}
                    />
                  ))}
                </tbody>
              </table>
            )}
          </div>

          {/* create coin */}
          <CreateCoin
            isOpen={isCreateOpen}
            setIsOpen={setIsCreateOpen}
            addCoinMutation={addCoinMutation}
            networksData={networksData}
          />
        </div>
      </div>
    </Layout>
  );
}

function CryptoTableRow({ transaction }: { transaction: any }) {
  const { data: networksData } = useGetNetworks();
  const [isEdit, setIsEdit] = useState(false);

  const [isEditOpen, setIsEditOpen] = useState(false);
  const [editedData, setEditedData] = useState(null);
  console.log(transaction);
  return (
    <>
      <tr className={"bg-[#FFDEEA] "}>
        <td className="rounded-l-lg px-2 border border-black-800/30 border-r-0 py-5">
          <p className="text-black-900 text-xs font-semibold pl-3">
            {transaction?.coin?.symbol}
          </p>
        </td>
        <td className="py-5 px-5 border-y border-black-800/30">
          <p className="text-black-900 text-sm text-center flex items-center gap-2">
            {transaction?.coin?.name}
            <div>
              <img src={transaction?.coin?.icon} width={"20px"} />
            </div>
          </p>
        </td>
        <td className="py-5 px-5 border-y border-black-800/30 text-left">
          <p className="text-black-900 text-xs ml-3">
            {Number(transaction?.applicationBalance || 0)?.toLocaleString(
              undefined,
              {
                maximumFractionDigits: 6,
              }
            )}{" "}
            {transaction?.coin?.symbol}
          </p>
        </td>

        <td className="py-5 px-5 border-y border-black-800/30">
          <p className="text-black-900 text-xs font-semibold">
            {Number(transaction?.exchangeBalance || 0)?.toLocaleString(
              undefined,
              {
                maximumFractionDigits: 6,
              }
            )}{" "}
            {transaction?.coin?.symbol} ({transaction?.exchangeName})
          </p>
        </td>
        <td className="rounded-r-lg px-0 border border-black-800/30 border-l-0 py-5 flex justify-end">
          <button
            onClick={() => setIsEdit(!isEdit)}
            type="button"
            className={`${"bg-[#FF689F] text-white"} text-xs font-semibold rounded-lg px-5 py-2`}
          >
            {isEdit ? "Collapse" : "See Details"}
          </button>
          <button
            onClick={() => {
              setIsEditOpen(true);
              setEditedData(transaction?.coin);
            }}
            type="button"
            className={`${"bg-[cyan] "} text-xs font-semibold rounded-lg px-5 py-2 mx-2`}
          >
            Edit
          </button>
        </td>
      </tr>
      <tr
        className={`${"bg-[#FFDEEA] after:bg-[#FFDEEA]"} ${
          isEdit
            ? "relative after:absolute after:border-x after:border-black-800/30 after:left-0 after:bottom-[100%] after:block after:w-[100%] after:h-[18px]"
            : ""
        }
        
        `}
      >
        <td
          colSpan={5}
          className={`p-0 ${
            isEdit ? "border border-black-800/30" : ""
          } rounded-b-lg border-t-0`}
        >
          {isEdit && (
            <table className="px-6 pb-6">
              <tr>
                <td>
                  <strong className="text-xs">address</strong>
                  <span className="text-xs mt-3 block">
                    {transaction?.coin?.contractAddress}
                  </span>
                </td>
                <td valign="top">
                  <strong className="text-xs">Coin Gecko Id</strong>
                  <span className="text-xs mt-3 block">
                    {transaction?.coin?.coinNameId}
                  </span>
                </td>
                <td valign="top">
                  <strong className="text-xs">Available Currencies</strong>
                  <span className="text-xs mt-3 block">
                    {transaction?.coin?.currencyYouCanSwapWith?.map(
                      (currency: any) => <>{currency?.name} </>
                    )}
                  </span>
                </td>
                {transaction?.currency && (
                  <td valign="top">
                    <strong className="text-xs">Currency</strong>
                    <span className="text-xs mt-3 block">
                      {transaction?.currency}
                    </span>
                  </td>
                )}
              </tr>
            </table>
          )}
        </td>
      </tr>
      <EditCoin
        isOpen={isEditOpen}
        setIsOpen={setIsEditOpen}
        data={editedData}
        setData={setEditedData}
        selected={"crypto"}
        networksData={networksData}
      />
    </>
  );
}

function FiatTableRow({ transaction }: { transaction: any }) {
  const [isEdit, setIsEdit] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [editedData, setEditedData] = useState(null);
  return (
    <>
      <tr className={"bg-[#FFDEEA] "}>
        <td className="rounded-l-lg px-2 border border-black-800/30 border-r-0 py-5">
          <p className="text-black-900 text-xs ml-3">
            {new Date(transaction?.date).toLocaleDateString("us", {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </p>
        </td>
        <td className="py-5 px-5 border-y border-black-800/30">
          <p className="text-black-900 text-xs font-semibold">
            <b></b> {transaction?.name}
          </p>
        </td>
        <td className="py-5 px-5 border-y border-black-800/30">
          <p className="text-black-900 text-xs font-semibold">
            {transaction?.withdrawn?.join(", ")}
          </p>
        </td>
        <td className="rounded-r-lg px-0 border border-black-800/30 border-l-0 py-5 flex justify-end">
          <button
            onClick={() => setIsEdit(!isEdit)}
            type="button"
            className={`${"bg-[#FF689F] text-white"} text-xs font-semibold rounded-lg px-5 py-2`}
          >
            {isEdit ? "Collapse" : "See Details"}
          </button>
          <button
            onClick={() => {
              setIsEditOpen(true);
              setEditedData(transaction);
            }}
            type="button"
            className={`${"bg-[cyan] "} text-xs font-semibold rounded-lg px-5 py-2 mx-2`}
          >
            Edit
          </button>
        </td>
      </tr>
      <tr
        className={`${"bg-[#FFDEEA] after:bg-[#FFDEEA]"} ${
          isEdit
            ? "relative after:absolute after:border-x after:border-black-800/30 after:left-0 after:bottom-[100%] after:block after:w-[100%] after:h-[18px]"
            : ""
        }
        
        `}
      >
        <td
          colSpan={5}
          className={`p-0 ${
            isEdit ? "border border-black-800/30" : ""
          } rounded-b-lg border-t-0`}
        >
          {isEdit && (
            <table className="px-6 pb-6">
              <tr>
                <td valign="top">
                  <strong className="text-xs">Available Currencies</strong>
                  <span className="text-xs mt-3 block">
                    {transaction?.availableCurrencies?.join(" / ")}
                  </span>
                </td>
                {transaction?.currency && (
                  <td valign="top">
                    <strong className="text-xs">Currency</strong>
                    <span className="text-xs mt-3 block">
                      {transaction?.currency}
                    </span>
                  </td>
                )}
              </tr>
            </table>
          )}
        </td>
      </tr>
      <EditCoin
        isOpen={isEditOpen}
        setIsOpen={setIsEditOpen}
        data={editedData}
        setData={setEditedData}
        selected={transaction?.currency ? "fiat" : "crypto"}
      />
    </>
  );
}
