import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Select from "react-select";
import toast from "react-hot-toast";
import { useUploadMedia } from "utils/api/media.api";
import { useGetSwapCoins } from "utils/api/user.api";

const priceOptions = [
  { value: "CoinGecko", label: "Coin Gecko" },
  { value: "CryptoCompare", label: "Crypto Compare" },
];

interface FormData {
  name: string;
  contractAddress: string;
  symbol: string;
  tokenPriceFrom: string;
  coinNameId: string;
  icon: string;
  tokenWithdrawn: string[];
  currencyYouCanSwap: string[];
  swapped: string;
  isToken: boolean;
  decimal: number;
  networkId: string;
  unit: string;
  isStableCoin: boolean;
  bitfinixCoinId: string;
  bitfinixCoinSymbol: string;
}

export default function CreateCoin({
  isOpen,
  setIsOpen,
  addCoinMutation,
  networksData,
}: any) {
  const uploadMediaMutation = useUploadMedia();
  const { data: swappedCoins, isLoading } = useGetSwapCoins();

  const [, setUrl] = useState();
  const [isSwapped, setIsSwapped] = useState(false); // State to track the checkbox value
  const [swapOptions, setSwapOptions] = useState([]);

  const [formData, setFormData] = useState<FormData>({
    name: "",
    contractAddress: "",
    symbol: "",
    tokenPriceFrom: "",
    coinNameId: "",
    icon: "",
    tokenWithdrawn: [],
    currencyYouCanSwap: [],
    swapped: "",
    isToken: true,
    decimal: 0,
    networkId: "",
    unit: "",
    isStableCoin: false,
    bitfinixCoinId: "",
    bitfinixCoinSymbol: "",
  });
  const handleCheckboxChange = (e: any) => {
    const isChecked = e.target.checked;
    const updatedSwappedValue = isChecked ? "Bitfinex" : ""; // Set to "CEX (Binance Exchange)" if checked, otherwise empty string
    setIsSwapped(isChecked);
    setFormData({ ...formData, swapped: updatedSwappedValue });
  };

  const handleChange = (e: any) => {
    const { name, value, type, checked } = e.target;
    if (name === "decimal") {
      const newValue = Math.max(1, parseInt(e.target.value));
      setFormData((prevState) => ({
        ...prevState,
        [name]: newValue,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
  };

  const handleCurrencySwapChange = (selectedOptions: any) => {
    // Extracting values from selected options
    const selectedValues = selectedOptions.map((option: any) => option.value);
    setFormData({
      ...formData,
      currencyYouCanSwap: selectedValues,
    });
  };

  const handleWithdrawOptionChange = (option: any) => {
    const updatedWithdrawnOptions: any = formData.tokenWithdrawn.includes(
      option
    )
      ? formData.tokenWithdrawn.filter((item) => item !== option)
      : [...formData.tokenWithdrawn, option];

    setFormData({
      ...formData,
      tokenWithdrawn: updatedWithdrawnOptions,
    });
  };

  // const handleCheckboxChange = (withdrawType: string) => {
  //   if (withdrawTypes.includes(withdrawType)) {
  //     setWithdrawTypes(withdrawTypes.filter((type) => type !== withdrawType));
  //   } else {
  //     setWithdrawTypes([...withdrawTypes, withdrawType]);
  //   }
  // };

  const handleFileUpload = async (file: any) => {
    if (file) {
      try {
        const formData1: any = new FormData();
        formData1.append("file", file);
        const url = await uploadMediaMutation.mutateAsync(formData1);
        setUrl(url);
        setFormData({ ...formData, icon: url });
      } catch (error: any) {
        toast.error(error.message);
      }
    }
  };

  useEffect(() => {
    if (swappedCoins) {
      const options: any = [...swapOptions];
      options.push(
        ...swappedCoins.map((coin: any) => ({
          value: coin?.id,
          label: coin?.symbol,
        }))
      );
      setSwapOptions(options);
    }
  }, [swappedCoins]);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-4 sm:pb-4">
                  <div className="">
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Create Crypto Coin/Token
                      </Dialog.Title>
                      <div className="mt-4">
                        <div className="mt-2">
                          <label
                            htmlFor="name"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Coin/Token name
                          </label>
                          <div className="mt-2">
                            <input
                              id="name"
                              name="name"
                              type="text"
                              value={formData.name}
                              onChange={handleChange}
                              required
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-0 ps-2 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            />
                          </div>
                          <label
                            htmlFor="contractAddress"
                            className="mt-2 block text-sm font-medium leading-6 text-gray-900"
                          >
                            EVM Token Contract Address{" "}
                            {!formData.isToken && "(Optional)"}
                          </label>
                          <div className="mt-2">
                            <input
                              id="contractAddress"
                              name="contractAddress"
                              type="text"
                              value={formData.contractAddress}
                              onChange={handleChange}
                              required
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-0 ps-2 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            />
                          </div>
                          {/* <label
                            htmlFor="unit"
                            className="mt-2 block text-sm font-medium leading-6 text-gray-900"
                          >
                            Unit
                          </label>
                          <div className="mt-2">
                            <input
                              id="unit"
                              name="unit"
                              type="text"
                              value={formData.unit}
                              onChange={handleChange}
                              required
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-0 ps-2 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            />
                          </div> */}
                          <div className="mt-2 flex items-center">
                            <input
                              id="isStableCoin"
                              name="isStableCoin"
                              type="checkbox"
                              checked={formData.isStableCoin}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  isStableCoin: e.target.checked,
                                })
                              }
                              className="h-4 w-4 text-cyan-600 focus:ring-cyan-500 border-gray-300 rounded cursor-pointer"
                            />
                            <label
                              htmlFor="isStableCoin"
                              className="ml-2 block text-sm font-medium text-gray-900"
                            >
                              Stable Coin
                            </label>
                          </div>
                          <div className="mt-2 flex items-center">
                            <input
                              id="isToken"
                              name="isToken"
                              type="checkbox"
                              checked={formData.isToken}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  isToken: e.target.checked,
                                })
                              }
                              className="h-4 w-4 text-cyan-600 focus:ring-cyan-500 border-gray-300 rounded cursor-pointer"
                            />
                            <label
                              htmlFor="isToken"
                              className="ml-2 block text-sm font-medium text-gray-900"
                            >
                              Token
                            </label>
                          </div>
                          <label
                            htmlFor="decimal"
                            className="mt-2 block text-sm font-medium leading-6 text-gray-900"
                          >
                            Decimal
                          </label>
                          <div className="mt-2">
                            <input
                              id="decimal"
                              name="decimal"
                              type="number"
                              value={formData.decimal}
                              placeholder="Enter between 1-18"
                              onChange={handleChange}
                              required
                              min={1} // Set the minimum value
                              max={18} // Set the maximum value
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-0 ps-2 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            />
                          </div>

                          <label
                            htmlFor="networkId"
                            className="mt-2 block text-sm font-medium leading-6 text-gray-900"
                          >
                            Network ID
                          </label>
                          <div className="mt-2">
                            <select
                              id="networkId"
                              name="networkId"
                              value={formData.networkId}
                              onChange={handleChange}
                              required
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-0 ps-2 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            >
                              <option value="">Select Network</option>
                              {networksData?.map((network: any) => (
                                <option key={network.id} value={network.id}>
                                  {network.networkName}
                                </option>
                              ))}
                            </select>
                          </div>

                          <label
                            htmlFor="symbol"
                            className="mt-2 block text-sm font-medium leading-6 text-gray-900"
                          >
                            Symbol
                          </label>

                          <div className="mt-2">
                            <input
                              id="symbol"
                              name="symbol"
                              type="text"
                              value={formData.symbol}
                              onChange={handleChange}
                              required
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-0 ps-2 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            />
                          </div>
                          <label
                            htmlFor="token Price"
                            className="mt-2 block text-sm font-medium leading-6 text-gray-900"
                          >
                            Token price from:
                          </label>
                          <div className="mt-2 text-gray-900 font-medium text-sm">
                            <Select
                              options={priceOptions}
                              onChange={(value: any) =>
                                setFormData({
                                  ...formData,
                                  tokenPriceFrom: value.value,
                                })
                              }
                            />
                          </div>
                          <label
                            htmlFor="coinNameId"
                            className="mt-2 block text-sm font-medium leading-6 text-gray-900"
                          >
                            Coingecko id
                          </label>
                          <div className="mt-2">
                            <input
                              id="coinNameId"
                              name="coinNameId"
                              type="text"
                              value={formData.coinNameId}
                              onChange={handleChange}
                              required
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-0 ps-2 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            />
                          </div>

                          <label
                            htmlFor="icon"
                            className="mt-2 block text-sm font-medium leading-6 text-gray-900"
                          >
                            Logo
                          </label>
                          <div className="mt-2">
                            <input
                              id="icon"
                              name="icon"
                              type="file"
                              accept="image/*"
                              // value={formData.icon}
                              onChange={(e: any) => {
                                const selectedFile = e.target.files[0];
                                if (selectedFile) {
                                  if (selectedFile.type.startsWith("image/")) {
                                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                                    handleFileUpload(selectedFile);
                                  } else {
                                    toast.error(
                                      "Please select a valid image file."
                                    );
                                    e.target.value = null;
                                  }
                                }
                              }}
                              required
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-0 ps-2 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            />
                          </div>

                          <div className="mt-2">
                            <label
                              htmlFor="coinName"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              How can this token be withdrawn?{" "}
                              {!formData.isToken && "(Optional)"}
                            </label>
                            <div className="mt-2 flex gap-5">
                              <div className="mt-2 flex gap-2 items-center ">
                                <input
                                  id="cash_check"
                                  name="cash"
                                  type="checkbox"
                                  checked={formData.tokenWithdrawn.includes(
                                    "cash"
                                  )}
                                  onChange={() =>
                                    handleWithdrawOptionChange("cash")
                                  }
                                  required
                                />
                                <label
                                  htmlFor="cash_check"
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                  Cash
                                </label>
                              </div>
                              <div className="mt-2 flex gap-2 items-center ">
                                <input
                                  id="bank_check"
                                  name="bank"
                                  type="checkbox"
                                  checked={formData.tokenWithdrawn.includes(
                                    "bank"
                                  )}
                                  onChange={() =>
                                    handleWithdrawOptionChange("bank")
                                  }
                                  required
                                />
                                <label
                                  htmlFor="bank_check"
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                  Bank
                                </label>
                              </div>
                              <div className="mt-2 flex gap-2 items-center ">
                                <input
                                  id="mobile_check"
                                  name="mobile"
                                  type="checkbox"
                                  checked={formData?.tokenWithdrawn?.includes(
                                    "mobile"
                                  )}
                                  onChange={() =>
                                    handleWithdrawOptionChange("mobile")
                                  }
                                  required
                                />
                                <label
                                  htmlFor="mobile_check"
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                  Mobile Money
                                </label>
                              </div>
                            </div>
                          </div>

                          <label
                            htmlFor="cex-check-label"
                            className="block mt-3 text-sm font-medium leading-6 text-gray-900"
                          >
                            How it can be swapped?{" "}
                            {!formData.isToken && "(Optional)"}
                          </label>

                          <div className="mt-2 flex gap-2 items-center">
                            <input
                              id="Bitfinex"
                              name="logo"
                              type="checkbox"
                              checked={isSwapped}
                              onChange={handleCheckboxChange}
                              required
                            />
                            <label
                              htmlFor="Bitfinex"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Bitfinex
                            </label>
                          </div>
                          {isSwapped && (
                            <>
                              <label
                                htmlFor="coinNameId"
                                className="mt-2 block text-sm font-medium leading-6 text-gray-900"
                              >
                                Bitfinex Coin Id
                              </label>
                              <div className="mt-2">
                                <input
                                  id="coinNameId"
                                  name="coinNameId"
                                  type="text"
                                  value={formData.coinNameId}
                                  onChange={handleChange}
                                  required
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-0 ps-2 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                                />
                              </div>
                              <label
                                htmlFor="coinNameId"
                                className="mt-2 block text-sm font-medium leading-6 text-gray-900"
                              >
                                Bitfinex Coin Symbol
                              </label>
                              <div className="mt-2">
                                <input
                                  id="coinNameId"
                                  name="coinNameId"
                                  type="text"
                                  value={formData.coinNameId}
                                  onChange={handleChange}
                                  required
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-0 ps-2 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                                />
                              </div>
                              <label
                                htmlFor="swap"
                                className="mt-2 block text-sm font-medium leading-6 text-gray-900"
                              >
                                Currencies you can swap{" "}
                              </label>
                              <div className="mt-2 ">
                                <Select
                                  options={swapOptions}
                                  isMulti
                                  isLoading={isLoading}
                                  onChange={handleCurrencySwapChange}
                                  menuPlacement="auto"
                                />
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    disabled={addCoinMutation?.isPending}
                    className="inline-flex w-full justify-center rounded-md bg-cyan-300 px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-cyan-800 sm:ml-3 sm:w-auto ring-1 ring-inset ring-cyan-800"
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onClick={async () => {
                      try {
                        if (formData?.isToken) {
                          if (
                            formData?.name === "" ||
                            formData?.contractAddress === "" ||
                            // formData?.unit === "" ||
                            formData?.networkId === "" ||
                            formData?.symbol === "" ||
                            formData?.tokenPriceFrom === "" ||
                            formData?.coinNameId === "" ||
                            formData?.icon === "" ||
                            formData?.tokenWithdrawn?.length === 0
                          ) {
                            toast.error("Please fill all fields");
                            return;
                          }
                        } else {
                          if (
                            formData?.name === "" ||
                            formData?.networkId === "" ||
                            formData?.symbol === "" ||
                            formData?.tokenPriceFrom === "" ||
                            formData?.coinNameId === "" ||
                            formData?.icon === ""
                          ) {
                            toast.error("Please fill all fields");
                            return;
                          }
                        }
                        if (
                          Number(formData?.decimal) < 1 ||
                          Number(formData?.decimal) > 18
                        ) {
                          toast.error("Decimal must be between 1-18");
                          return;
                        }
                        if (isSwapped) {
                          if (
                            formData?.bitfinixCoinId === "" ||
                            formData?.bitfinixCoinSymbol === ""
                          ) {
                            toast.error("Please fill all fields");
                            return;
                          }
                        }
                        await addCoinMutation?.mutateAsync(formData);
                        toast.success("Coin added successfully!!!");
                        setFormData({
                          name: "",
                          contractAddress: "",
                          symbol: "",
                          tokenPriceFrom: "",
                          coinNameId: "",
                          icon: "",
                          tokenWithdrawn: [],
                          currencyYouCanSwap: [],
                          swapped: "",
                          isToken: true,
                          decimal: 0,
                          networkId: "",
                          unit: "",
                          isStableCoin: false,
                          bitfinixCoinSymbol: "",
                          bitfinixCoinId: "",
                        });
                        setIsOpen(false);
                      } catch (error: any) {
                        toast.error(error?.message);
                      }
                    }}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => {
                      setIsOpen(false);
                      setFormData({
                        name: "",
                        contractAddress: "",
                        symbol: "",
                        tokenPriceFrom: "",
                        coinNameId: "",
                        icon: "",
                        tokenWithdrawn: [],
                        currencyYouCanSwap: [],
                        swapped: "",
                        isToken: true,
                        decimal: 0,
                        networkId: "",
                        unit: "",
                        isStableCoin: false,
                        bitfinixCoinSymbol: "",
                        bitfinixCoinId: "",
                      });
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
