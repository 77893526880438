import Layout from "layout";
import React from "react";
import { useGetTransactionActivity } from "utils/api/auth.api";

function PlatformFee() {
  const { data } = useGetTransactionActivity();
  return (
    <Layout>
      <div className="dashboard-main">
        <div className="main-wrapper max-w-5xl p-6">
          <h1 className="text-xl font-bold">Platform Fee</h1>
          <div className="grid grid-cols-12 gap-4 lg:gap-8 mt-4 mb-12">
            <div className="col-span-12 md:col-span-6 lg:col-span-3">
              <div className="hover:bg-cyan-800 bg-cyan-300 rounded-2xl transition p-6">
                <p className="text-black-800 text-sm font-semibold mb-3">
                  Total Amount
                </p>
                <h4 className="text-black-800 text-2xl font-semibold">
                  {data?.totalAmount || 0}
                </h4>
              </div>
            </div>
            <div className="col-span-12 md:col-span-6 lg:col-span-3">
              <div className="hover:bg-cyan-800 bg-cyan-300 rounded-2xl transition p-6">
                <p className="text-black-800 text-sm font-semibold mb-3">
                  Total Fee
                </p>
                <h4 className="text-black-800 text-2xl font-semibold">
                  {data?.totalFee || 0}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default PlatformFee;
