import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  type VerifyEmailForm,
  type User,
  type Register,
  type UserLogin,
  type VerifyAndUpgradeKyc,
} from "utils/types/user.type";
import { client } from "./api-client";

export const useLogin = () =>
  useMutation<
    { token: { access_token: string }; user: User },
    Error,
    UserLogin
  >(
    async (data) =>
      await client("auth/login", {
        data,
      })
  );

export const useSignUp = () =>
  useMutation<{ user: User }, Error, Register>(
    async (data) =>
      await client("auth/signupAdmin", {
        data,
      })
  );

export const useVerifyEmail = () =>
  useMutation<
    { user: User; token: { access_token: string } },
    Error,
    VerifyEmailForm
  >(
    async (data) =>
      await client(`auth/verifyEmail`, {
        data,
      })
  );

export const useGetOtp = () =>
  useMutation<unknown, Error, string>(
    async (email) =>
      await client(`auth/forgotPassword`, {
        data: { email },
      })
  );

export const useVerifyOtp = () =>
  useMutation<
    {
      token: {
        access_token: string;
      };
    },
    Error,
    VerifyEmailForm
  >(
    async (data) =>
      await client(`auth/verifyOtpForForgotPassword`, {
        data,
      })
  );

export const useReSendOtp = () =>
  useMutation<unknown, Error, string>(
    async (email) =>
      await client("auth/resendOtp", {
        data: { email },
      })
  );

export const useResetPassword = () =>
  useMutation<unknown, Error, string>(
    async (password) =>
      await client("auth/resetPassword", {
        data: { password },
        headers: {
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions, @typescript-eslint/prefer-nullish-coalescing
          Authorization: localStorage.getItem("otp_token") || "",
        },
      })
  );

export const useChangePassword = () =>
  useMutation<unknown, Error, string>(
    async (password) =>
      await client("user/changePassword", {
        data: { password },
      })
  );

export const useGetKycVerificationsRecords = () =>
  useQuery<any[], Error>(
    ["getKycVerificationsRecords"],
    async () => await client(`auth/getKycVerificationsRecords`)
  );

export const useGetKycHistory = () =>
  useQuery<any[], Error>(
    ["getKycHistory"],
    async () => await client(`auth/getKycHistory`)
  );

export const useAdminVerifyAndUpgradeKYC = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, Error, VerifyAndUpgradeKyc>(
    async (data) => {
      const res = await client("auth/AdminVerifyAndUpgradeKYC", {
        data,
      });

      return res;
    },
    {
      onSuccess() {
        void queryClient.invalidateQueries(["getKycVerificationsRecords"]);
      },
    }
  );
};

export const useGetKycLimits = () =>
  useQuery<any[], Error>(
    ["getKycLimits"],
    async () => await client(`auth/getKycLimits`)
  );

export const useUpdateKycLimits = () => {
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    Error,
    { recordId: string; isActive: boolean; kycNote: string }
  >(
    async (data) => {
      const res = await client("auth/updateKycLimits", {
        data,
      });

      return res;
    },
    {
      onSuccess() {
        void queryClient.invalidateQueries(["getKycLimits"]);
      },
    }
  );
};

export const useAddKycTwoDocument = () => {
  const queryClient = useQueryClient();
  return useMutation<unknown, Error, { documents: string }>(
    async (data) => {
      const res = await client("auth/addKycTwoDocument", {
        data,
      });

      return res;
    },
    {
      onSuccess() {
        void queryClient.invalidateQueries(["getKycTwoDocumentsForAdmin"]);
      },
    }
  );
};

export const useGetKycTwoDocumentsForAdmin = () =>
  useMutation<any, Error>(async () => {
    const response = await client(`auth/getKycTwoDocumentsForAdmin`, {
      method: "POST",
    });
    return response;
  });

export const useGetSupportURL = () =>
  useQuery<any, Error>(
    ["getSupportURL"],
    async () => await client(`auth/getSupportUrl`)
  );

export const useUpdateSupportURL = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, Error, { url: string }>(
    async (url) => {
      const res = await client("auth/updateSupportUrl", {
        data: url,
      });

      return res;
    },
    {
      onSuccess() {
        void queryClient.invalidateQueries(["getSupportURL"]);
      },
    }
  );
};

export const useGetTransactionActivity = () =>
  useQuery<any, Error>(
    ["getTransactionActivity"],
    async () => await client(`auth/getTransactionActivity`)
  );
