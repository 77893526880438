import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import toast from "react-hot-toast";
import Select from "react-select";

const ChangeAvailable = ({
  isOpen,
  setIsOpen,
  data,
  updateCoinAvailabilityMutations,
}: any) => {
  console.log(data);
  const [selectedOption, setSelectedOption] = useState({
    value: data?.isActive ? "yes" : "no",
    label: data?.isActive ? "Yes" : "No",
  });

  const options = [
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
  ];

  const handleAvailabilityChange = async () => {
    const newAvailability = selectedOption.value === "yes";
    await updateCoinAvailabilityMutations.mutateAsync({
      coinId: data?.id,
      isActive: newAvailability,
    });
    toast.success("Coin Availability Changed Successfully!!!");
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-4 sm:pb-4">
                  <div className="">
                    <div className="mt-3 mb-5 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Change Availability
                      </Dialog.Title>
                      <div className="mt-4">
                        <div className="mt-2">
                          <label
                            htmlFor="swap"
                            className="mt-2 block text-sm font-medium leading-6 text-gray-900"
                          >
                            Change To
                          </label>
                          <div className="mt-2">
                            <Select
                              options={options}
                              value={selectedOption}
                              onChange={(option: any) =>
                                setSelectedOption(option)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-cyan-300 px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-cyan-800 sm:ml-3 sm:w-auto ring-1 ring-inset ring-cyan-800"
                    onClick={() => {
                      // eslint-disable-next-line @typescript-eslint/no-floating-promises
                      handleAvailabilityChange();
                      setIsOpen(false);
                    }}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setIsOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ChangeAvailable;
