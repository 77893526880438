import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
} from "@chakra-ui/react";
import { FaEllipsisH } from "react-icons/fa";
import React from "react";

const ChakraUIMenu = ({ options, onClick }: any) => {
  return (
    <Menu>
      <MenuButton as={IconButton} icon={<FaEllipsisH />} variant="ghost" />
      <MenuList
        bg={"white"}
        p={"10px"}
        border={"1px solid green"}
        zIndex={"20"}
        borderRadius={"10px"}
        minW={"150px"}
        ml={"-160px"}
        className="shadow-lg"
      >
        {options.map((item: string, index: number) => (
          <MenuItem
            key={index}
            p={"8px"}
            borderRadius={"5px"}
            mb={"5px"}
            _hover={{ backgroundColor: "#EFFFFD" }}
            onClick={() => {
              onClick(item);
            }}
          >
            {item}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default ChakraUIMenu;
