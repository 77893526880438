import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { client } from "./api-client";
import { type BinanceKey } from "utils/types/api_keys.type";

export const getBinanceKeys = () =>
  useQuery<BinanceKey[], Error>(
    ["getBinanceKeys"],
    async () => await client(`auth/getBitfinexKeys`)
  );

export const setBinanceApiKey = () => {
  const queryClient = useQueryClient();

  return useMutation<
    BinanceKey,
    Error,
    { key?: string; secretKey?: string; id: string }
  >(
    async (data) => {
      const res = await client(
        `auth/updateBitfinexKeyById?bitfinixRecordId=${data.id}`,
        {
          data,
        }
      );

      return res;
    },
    {
      onSuccess() {
        void queryClient.invalidateQueries(["getBinanceKeys"]);
      },
    }
  );
};
