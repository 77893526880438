import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import PrivateRoute from "./privateRoute";
import PendingTransactions from "views/pendingTransactions";
import CompletedTransactions from "views/completedTransactions";
import AdminAccess from "views/adminAccess";
import ChangeEmailAddress from "views/changeEmail";
import ChangePassword from "views/changePassword";
import UserDictionary from "views/userDictionary";
import RateSettings from "views/rateSettings";
import Login from "views/login";
import Signup from "views/signup";
import ForgotPassword from "views/forgotPasswoard";
import VerifyEmail from "views/verifyEmail";
import ResetPassword from "views/resetPassword";
import Logout from "views/logout";
import Coins from "views/coins";
import WithdrawalSettings from "views/withdrawalSettings";
import APIs from "views/Apis";
import LimitManagement from "views/limitManagement";
import CryptoPrice from "views/cryptoPrice";
import Kyc from "views/kyc";
import Notifications from "views/notifications";
import Support from "views/support";
import PlatformFee from "views/platformFee";
import PendingDeposits from "views/pendingDeposits";

const routes = [
  {
    path: "/pending-withdrawals",
    Component: PendingTransactions,
    isPrivate: true,
  },
  {
    path: "/pending-deposits",
    Component: PendingDeposits,
    isPrivate: true,
  },
  {
    path: "/transaction-history",
    Component: CompletedTransactions,
    isPrivate: true,
  },
  {
    path: "/coins",
    Component: Coins,
    isPrivate: true,
  },
  {
    path: "/withdrawal-settings",
    Component: WithdrawalSettings,
    isPrivate: true,
  },
  {
    path: "/users",
    Component: UserDictionary,
    isPrivate: true,
  },
  {
    path: "/admin-management",
    Component: AdminAccess,
    isPrivate: true,
  },
  {
    path: "/change-email",
    Component: ChangeEmailAddress,
    isPrivate: true,
  },
  {
    path: "/apis",
    Component: APIs,
    isPrivate: true,
  },
  {
    path: "/app-wide-limits-management",
    Component: LimitManagement,
    isPrivate: true,
  },
  {
    path: "/kyc",
    Component: Kyc,
    isPrivate: true,
  },
  {
    path: "/notifications",
    Component: Notifications,
    isPrivate: true,
  },
  {
    path: "/crypto-price",
    Component: CryptoPrice,
    isPrivate: true,
  },
  {
    path: "/support",
    Component: Support,
    isPrivate: true,
  },
  {
    path: "/change-password",
    Component: ChangePassword,
    isPrivate: true,
  },

  {
    path: "/earning-fee",
    Component: RateSettings,
    isPrivate: true,
  },
  {
    path: "/platform-fee",
    Component: PlatformFee,
    isPrivate: true,
  },
  {
    path: "/login",
    Component: Login,
    isPrivate: false,
  },
  {
    path: "/forgot-password",
    Component: ForgotPassword,
    isPrivate: false,
  },
  {
    path: "/reset-password",
    Component: ResetPassword,
    isPrivate: false,
  },
  {
    path: "/verify-email",
    Component: VerifyEmail,
    isPrivate: false,
  },
  {
    path: "/signup",
    Component: Signup,
    isPrivate: false,
  },
  {
    path: "/logout",
    Component: Logout,
    isPrivate: false,
  },
];

export default function AppRoutes() {
  return (
    <Routes>
      {routes?.map(({ path, Component, isPrivate }) => (
        <Route
          key={path}
          element={
            isPrivate ? (
              <PrivateRoute component={<Component />} />
            ) : (
              <Component />
            )
          }
          path={path}
        />
      ))}
      <Route path="*" element={<Navigate to="/pending-withdrawals" />} />
    </Routes>
  );
}
