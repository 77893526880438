import Layout from "layout";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  useGetCoins,
  useGetKycTransactionLimit,
  useSetKycTransactionLimit,
} from "utils/api/user.api";

import CryptoPrice from "views/cryptoPrice";

const LimitManagement = () => {
  const [activeTab, setActiveTab] = useState<string>("Limits");

  // getting data for server by name
  const [limitData, setLimitData] = useState({
    swapUpperLimit: 0,
    withdrawUpperLimit: 0,
  });

  const { data } = useGetKycTransactionLimit("Email Validation Limit");
  const { data: coinsList, isLoading } = useGetCoins();
  useEffect(() => {
    if (data) {
      setLimitData({
        swapUpperLimit: data?.swapUpperLimit,
        withdrawUpperLimit: data?.withdrawUpperLimit,
      });
    }
  }, [data]);

  // sending data to server (i.e upper limits)
  const setKycTransactionLimitMutation = useSetKycTransactionLimit();

  return (
    <Layout>
      <div className="dashboard-main">
        <div className="main-wrapper max-w-5xl p-6">
          <ul className="flex flex-wrap items-center gap-3 mb-4">
            <li>
              <div
                onClick={() => setActiveTab("Limits")}
                className={` ${
                  activeTab === "Limits"
                    ? "text-black-900"
                    : "text-black-900/20"
                } text-xl cursor-pointer`}
              >
                Limits
              </div>
            </li>
            <li>
              <div
                onClick={() => setActiveTab("Crypto Limits")}
                className={` ${
                  activeTab === "Crypto Limits"
                    ? "text-black-900"
                    : "text-black-900/20"
                } text-xl cursor-pointer`}
              >
                Crypto Limits
              </div>
            </li>
          </ul>

          {activeTab === "Crypto Limits" ? (
            <CryptoPrice data={coinsList} isLoading={isLoading} />
          ) : (
            // keys?.map((apiKey: any) => (
            <div
              key={data?.id}
              className={`bg-cyan-300 rounded-lg flex items-center p-5 gap-4 mt-5 justify-between`}
            >
              {/* <p className="text-lg font-bold  flex items-center gap-4 w-[200px] ">
                {data?.name}
              </p> */}
              <div
                className={`bg-cyan-300 rounded-lg flex items-center  gap-3 `}
              >
                <div className=" text-right">
                  <span className="block text-xs ">Swap Limit</span>
                  <span className="font-bold text-[14px]">
                    {/* {getFee("withdraw_fiat_fee")}% */}
                    {data?.swapUpperLimit}
                  </span>
                </div>
                <input
                  type="number"
                  min={0}
                  placeholder="Enter new Swap Limit"
                  value={limitData?.swapUpperLimit}
                  onChange={(e: any) => {
                    const value = Math.max(0, parseInt(e.target.value));
                    setLimitData({
                      ...limitData,
                      swapUpperLimit: value,
                    });
                  }}
                  className="block rounded-md border-0 px-1.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                <button
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  onClick={async () => {
                    try {
                      const formData = {
                        name: data?.name,
                        swapUpperLimit: limitData?.swapUpperLimit,
                        isActive: true,
                      };
                      await setKycTransactionLimitMutation.mutateAsync(
                        formData
                      );
                      toast.success("Swap Updated Successfully!!!");
                    } catch (error: any) {
                      toast.error(error.message);
                    }
                  }}
                  className="bg-cyan-800  border border-cyan-300 rounded-md text-black-700 text-md font-bold transition px-2 py-2 sm:text-sm"
                  disabled={setKycTransactionLimitMutation?.isLoading}
                >
                  {setKycTransactionLimitMutation?.isLoading ? (
                    <div
                      className="w-5 h-5 rounded-full animate-spin mx-auto
                      border-2 border-solid border-black-800 border-t-transparent"
                    ></div>
                  ) : (
                    "Update"
                  )}
                </button>
              </div>
              <div
                className={`bg-cyan-300 rounded-lg flex items-center   gap-4 `}
              >
                <div className="text-right">
                  <span className="block text-xs ">Withdraw Limit</span>
                  <span className="font-bold text-[14px]">
                    {/* {getFee("withdraw_fiat_fee")}% */}
                    {data?.withdrawUpperLimit}
                  </span>
                </div>
                <input
                  type="number"
                  min={0}
                  placeholder="Enter new fee"
                  value={limitData?.withdrawUpperLimit}
                  onChange={(e: any) => {
                    const value = Math.max(0, parseInt(e.target.value));
                    setLimitData({
                      ...limitData,
                      withdrawUpperLimit: value,
                    });
                  }}
                  className="block  rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                <button
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  onClick={async () => {
                    try {
                      const formData = {
                        name: data?.name,

                        withdrawUpperLimit: limitData?.withdrawUpperLimit,
                        isActive: true,
                      };
                      await setKycTransactionLimitMutation.mutateAsync(
                        formData
                      );
                      toast.success("WithdrawLimit Updated Successfully!!!");
                    } catch (error: any) {
                      toast.error(error.message);
                    }
                  }}
                  className="bg-cyan-800 border border-cyan-300 rounded-md text-black-700 text-md font-bold transition px-2 py-2 sm:text-sm"
                  disabled={setKycTransactionLimitMutation?.isLoading}
                >
                  {setKycTransactionLimitMutation?.isLoading ? (
                    <div
                      className="w-5 h-5 rounded-full animate-spin mx-auto
                      border-2 border-solid border-black-800 border-t-transparent"
                    ></div>
                  ) : (
                    "Update"
                  )}
                </button>
              </div>
            </div>
            //  ))
          )}
        </div>
      </div>
    </Layout>
  );
};

export default LimitManagement;
