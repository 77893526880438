import ChangeAvailable from "components/changeAvailable";
import React, { useState } from "react";
import { useGetCoins, useUpdateCoinAvailability } from "utils/api/user.api";

const AvailableCoins = () => {
  // const [stableCoins, setStableCoins] = useState([
  //   {
  //     id: 1,
  //     available: true,
  //     name: "TRX",
  //     symbol: "TRC20",
  //     logo: "https://cryptologos.cc/logos/tron-trx-logo.svg?v=029",
  //     address: "TWynZsd1MJjxPzgpYPas3exi3Zo6d3DLV",
  //     coinGeckoId: "36575747",
  //     stable: true,
  //   },
  //   {
  //     id: 2,
  //     available: true,
  //     name: "Ethereum",
  //     symbol: "ERC20",
  //     logo: "https://cryptologos.cc/logos/ethereum-eth-logo.png?v=029",
  //     address: "TWynZsd1MJjxPzgpYPas3exi3Zo6d3DLV",
  //     coinGeckoId: "36575747",
  //     stable: false,
  //   },
  //   {
  //     id: 3,
  //     available: false,
  //     name: "Bitcoin",
  //     symbol: "BTC",
  //     logo: "https://cryptologos.cc/logos/bitcoin-btc-logo.png?v=029",
  //     address: "TWynZsd1MJjxPzgpYPas3exi3Zo6d3DLV",
  //     coinGeckoId: "36575747",
  //     stable: true,
  //   },
  //   {
  //     id: 4,
  //     available: false,
  //     name: "Matic",
  //     symbol: "Polygon",
  //     logo: "https://cryptologos.cc/logos/polygon-matic-logo.svg?v=029",
  //     address: "TWynZsd1MJjxPzgpYPas3exi3Zo6d3DLV",
  //     coinGeckoId: "36575747",
  //     stable: true,
  //   },
  //   {
  //     id: 5,
  //     available: true,
  //     name: "USDT",
  //     symbol: "TRC20",
  //     logo: "https://cryptologos.cc/logos/bitcoin-btc-logo.png?v=029",
  //     address: "TWynZsd1MJjxPzgpYPas3exi3Zo6d3DLV",
  //     coinGeckoId: "36575747",
  //     stable: false,
  //   },
  //   {
  //     id: 6,
  //     available: true,
  //     name: "USDT",
  //     symbol: "ERC20",
  //     logo: "https://cryptologos.cc/logos/bitcoin-btc-logo.png?v=029",
  //     address: "TWynZsd1MJjxPzgpYPas3exi3Zo6d3DLV",
  //     coinGeckoId: "36575747",
  //     stable: true,
  //   },
  //   {
  //     id: 7,
  //     available: true,
  //     name: "USDC",
  //     symbol: "ERC20",
  //     logo: "https://cryptologos.cc/logos/bitcoin-btc-logo.png?v=029",
  //     address: "TWynZsd1MJjxPzgpYPas3exi3Zo6d3DLV",
  //     coinGeckoId: "36575747",
  //     stable: false,
  //   },
  //   {
  //     id: 8,
  //     available: false,
  //     name: "BNB",
  //     symbol: "BEP20",
  //     logo: "https://cryptologos.cc/logos/bitcoin-btc-logo.png?v=029",
  //     address: "TWynZsd1MJjxPzgpYPas3exi3Zo6d3DLV",
  //     coinGeckoId: "36575747",
  //     stable: true,
  //   },
  // ]);

  const { data } = useGetCoins();

  return (
    <div>
      <div className="table-wrapper max-h-[38rem] overflow-auto">
        <table className="w-full">
          <thead>
            <tr>
              <th className="rounded-l-lg border border-black-800/30 border-r-0 py-5 px-5 flex justify-start">
                Sr No
              </th>
              <th className="py-5 px-5 border-y border-black-800/30 text-left">
                Coin
              </th>
              <th className="py-5 px-5 border-y border-black-800/30 text-left">
                Symbol
              </th>
              <th className="py-5 px-5 border-y border-black-800/30 text-left">
                Availability
              </th>
              <th className="py-5 px-5 border-y border-black-800/30 text-left">
                Stable
              </th>
              <th className="rounded-r-lg border border-black-800/30 border-l-0 py-5 px-[50px] text-right">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {data
              // ?.filter((item: any) => item.name?.includes(searchValue))
              ?.map((transaction: any, idx: any) => (
                <TableRow
                  key={transaction?.id}
                  transaction={transaction}
                  idx={idx}
                  // onAvailabilityChange={(newAvailability: boolean) => {
                  //   const updatedCoins = stableCoins.map((coin) =>
                  //     coin.id === transaction.id
                  //       ? { ...coin, available: newAvailability }
                  //       : coin
                  //   );
                  //   setStableCoins(updatedCoins);
                  // }}
                />
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AvailableCoins;

function TableRow({
  transaction, // onAvailabilityChange,
  idx,
}: {
  transaction: any;
  idx: any;
  // onAvailabilityChange: (newAvailability: boolean) => void;
}) {
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [data, setData] = useState(transaction);
  const updateCoinAvailabilityMutation = useUpdateCoinAvailability();

  console.log(transaction, data);
  return (
    <>
      <tr className={"bg-[#FFDEEA] "}>
        <td className="rounded-l-lg px-5 border border-black-800/30 border-r-0 py-5">
          <p className="text-black-900 font-semibold">
            <b></b> {Number(idx) + Number(1)}
          </p>
        </td>
        <td className="py-5 px-5 border-y border-black-800/30">
          <p className="text-black-900  text-center flex gap-2">
            {transaction?.name}
          </p>
        </td>
        <td className="py-5 px-5 border-y border-black-800/30">
          <p className="text-black-900  text-center flex gap-2">
            {transaction?.symbol}
            <img src={transaction?.icon} width={"20px"} />
          </p>
        </td>
        <td className="py-5 px-5 border-y border-black-800/30">
          <p className="text-black-900  text-center flex gap-2">
            Available:{" "}
            <p className="font-semibold">
              {transaction?.isActive ? "Yes" : "No"}
            </p>
          </p>
        </td>
        <td className="py-5 px-5 border-y border-black-800/30">
          <p className="text-black-900  text-center flex gap-2">
            <p className="font-semibold">
              {transaction?.isStableCoin ? "Yes" : "No"}
            </p>
          </p>
        </td>

        <td className="rounded-r-lg px-5 border border-black-800/30 border-l-0  py-5 flex justify-end">
          <button
            onClick={() => {
              setIsEditOpen(true);
              setData(transaction);
            }}
            type="button"
            className={`font-semibold rounded-lg px-5 py-2`}
          >
            Change
          </button>
        </td>
      </tr>
      <ChangeAvailable
        isOpen={isEditOpen}
        setIsOpen={setIsEditOpen}
        setData={setData}
        data={data}
        updateCoinAvailabilityMutations={updateCoinAvailabilityMutation}
        // onAvailabilityChange={onAvailabilityChange}
      />
    </>
  );
}
