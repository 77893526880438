import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useUpdateCoinPrice } from "utils/api/user.api";

const CryptoPrice = ({ data, isLoading }: any) => {
  const updateCoinPrice = useUpdateCoinPrice();
  const [tempCoin, setTempCoin] = useState([]);

  useEffect(() => {
    setTempCoin(data);
  }, [data]);

  return (
    // <Layout>
    <div>
      {isLoading ? (
        <div
          className="w-7 h-7 rounded-full animate-spin mx-auto
    border-2 border-solid border-cyan-800 border-t-transparent"
        ></div>
      ) : (
        tempCoin?.map((coin: any, index: number) => (
          <div
            key={coin.id}
            className={`bg-cyan-300 rounded-lg flex items-center p-5 gap-4 mt-5`}
          >
            <p className="text-lg font-bold basis-3/12 flex items-center gap-4">
              <img src={coin?.icon} width={"20px"} />
              {coin?.symbol}
            </p>

            <div className="basis-3/12 ">
              <span className="block text-xs">Former Value</span>
              <span className="font-bold text-[16px]">{coin?.priceFormer}</span>
            </div>
            <div className="basis-3/12 ">
              <span className="block text-xs">Current Value</span>
              <span className="font-bold text-[16px]">{coin?.price}</span>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (!coin?.newPrice) {
                  toast.error("Please enter a new price");
                } else if (coin?.newPrice === coin?.price) {
                  toast.error(
                    "New price must be different from the current price"
                  );
                } else if (coin?.newPrice < 0) {
                  toast.error("New price must be greater than 0");
                } else {
                  updateCoinPrice
                    .mutateAsync({
                      price: coin?.newPrice,
                      coinId: coin?.id,
                    })
                    .then(() => {
                      const updatedTempCoin: any = [...tempCoin];
                      updatedTempCoin[index].newPrice = "";
                      setTempCoin(updatedTempCoin);
                      toast.success("Price updated successfully!");
                    })
                    .catch((err) => {
                      toast.error(err?.message || "Something went wrong");
                    });
                }
              }}
            >
              <div className="flex justify-ent items-center gap-4">
                <input
                  min={0}
                  type="number"
                  name={coin?.symbol}
                  value={coin?.newPrice}
                  onChange={(e) => {
                    const value = Math.max(0, parseInt(e.target.value));
                    const updatedTempCoin: any = [...tempCoin];
                    updatedTempCoin[index].newPrice = value;
                    setTempCoin(updatedTempCoin);
                  }}
                  placeholder="Update Limit"
                  className="block basis-2/12 rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                <button
                  type="submit"
                  disabled={updateCoinPrice.isLoading}
                  className="bg-cyan-800 basis-1/12 border border-cyan-300 rounded-md text-black-700 text-md font-bold transition px-8 py-2 sm:text-sm"
                >
                  {updateCoinPrice.isLoading ? (
                    <div
                      className="w-5 h-5 rounded-full animate-spin mx-auto
                      border-2 border-solid border-cyan-800 border-t-transparent"
                    ></div>
                  ) : (
                    "Update"
                  )}
                </button>
              </div>
            </form>
          </div>
        ))
      )}
    </div>
  );
};

export default CryptoPrice;

// : fiatKeys?.map((apiKey: any) => (
//     <div
//       key={apiKey.id}
//       className={`bg-cyan-300 rounded-lg flex items-center p-5 gap-4 mt-5`}
//     >
//       <p className="text-lg font-bold basis-3/12 flex items-center gap-4">
//         {apiKey?.keyName}
//       </p>

//       <div className="basis-3/12 ">
//         <span className="block text-xs">Former Value</span>
//         <span className="font-bold text-[16px]">
//           {apiKey?.keyValue}
//         </span>
//       </div>
//       <div className="basis-3/12 ">
//         <span className="block text-xs">Current Value</span>
//         <span className="font-bold text-[16px]">
//           {apiKey?.keyValue}
//         </span>
//       </div>
//       <div className="flex justify-ent items-center gap-4">
//         <input
//           onChange={(e) =>
//             setState({ ...state, keyValue: e.target.value })
//           }
//           value={state.keyValue}
//           type="number"
//           placeholder="Update Limit"
//           className="block basis-2/12 rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
//         />
//         <button
//           onClick={() => handleUpdateFiatApiKey(apiKey.id)}
//           className="bg-cyan-800 basis-1/12 border border-cyan-300 rounded-md text-black-700 text-md font-bold transition px-8 py-2 sm:text-sm"
//         >
//           Update
//         </button>
//       </div>
//     </div>
//   ))}
// </Layout>
