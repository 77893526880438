import Layout from "layout";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useGetSupportURL, useUpdateSupportURL } from "utils/api/auth.api";

const Support = () => {
  // geting data from server
  const { data } = useGetSupportURL();
  const updateURL = useUpdateSupportURL();

  const [url, setUrl] = useState("");

  return (
    <Layout>
      <div className="dashboard-main">
        <div className="main-wrapper max-w-5xl p-6">
          <h1 className="text-xl font-bold">Support URL</h1>

          {/* wokring */}
          {data ? (
            <div
              className={`bg-cyan-300 rounded-lg flex items-center p-5 gap-4 mt-5`}
            >
              <p className="text-lg font-bold basis-5/12 flex items-center gap-4">
                {data?.key || ""}
              </p>

              <div className="basis-2/12 text-right">
                <span className="block text-xs">Value</span>
                <span className="font-bold text-[16px]">
                  {data?.value || ""}
                </span>
              </div>
              <input
                type="text"
                placeholder="Update Value"
                className="block basis-2/12 rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
              />
              <button
                // eslint-disable-next-line  @typescript-eslint/no-misused-promises

                className="bg-cyan-800 basis-1/12 border border-cyan-300 rounded-md text-black-700 text-md font-bold transition px-8 py-2 sm:text-sm"
                disabled={updateURL?.isLoading}
                onClick={() => {
                  if (!url) {
                    toast.error("Please enter a value");
                  } else {
                    updateURL
                      .mutateAsync({ url })
                      .then((result) => {
                        toast.success("URL updated successfully");
                        setUrl("");
                      })
                      .catch((err) => {
                        toast.error(err?.message || "Something went wrong");
                      });
                  }
                }}
              >
                {updateURL?.isLoading ? (
                  <div
                    className="w-5 h-5 rounded-full animate-spin mx-auto
                      border-2 border-solid border-black-800 border-t-transparent"
                  ></div>
                ) : (
                  "Update"
                )}
              </button>
            </div>
          ) : (
            "No URL found"
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Support;
