import React, { useState } from "react";

const WithdrawalLimits = () => {
  const [state, setState] = useState<any>({
    feeName: "",
    feePercentage: 0,
  });

  const [fee, setFee] = useState<any>({
    id: 1,
    feeName: "swap_fee",
    feePercentage: 0,
  });

  return (
    <>
      <div
        className={`bg-cyan-300 rounded-lg flex items-center p-5 gap-4 mt-10`}
      >
        <p className="text-lg font-bold basis-5/12 flex items-center gap-4">
          Withdrawal Limit
        </p>

        <div className="basis-2/12 text-right">
          <span className="block text-xs">Limit</span>
          <span className="font-bold text-[16px]">{fee.feePercentage}</span>
        </div>
        <input
          onChange={(e) => {
            const value = Math.max(0, parseInt(e.target.value));
            setState({ feeName: "swap_fee", feePercentage: value });
          }}
          value={state.feePercentage}
          type="number"
          min={0}
          placeholder="Enter new fee"
          className="block basis-2/12 rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
        <button
          onClick={() => {
            setFee((prevFee: any) => ({
              ...prevFee,
              feePercentage: state.feePercentage,
            }));
            setState({
              feeName: "",
              feePercentage: 0,
            });
          }}
          className="bg-cyan-800 basis-1/12 border border-cyan-300 rounded-md text-black-700 text-md font-bold transition px-8 py-2 sm:text-sm"
        >
          Update
        </button>
      </div>
    </>
  );
};

export default WithdrawalLimits;
