import Layout from "layout";
import toast from "react-hot-toast";
import React, { useEffect, useState } from "react";
import { getBinanceKeys, setBinanceApiKey } from "utils/api/api_keys.api";
import { type BinanceKey } from "utils/types/api_keys.type";

const APIs = () => {
  // geting data from server
  const { data: received } = getBinanceKeys();

  // sending data to server
  const updateBinanceKey = setBinanceApiKey();

  const [keys, setKeys] = useState<BinanceKey[]>([]);

  useEffect(() => {
    if (received) {
      setKeys(received);
    }
  }, [received]);

  return (
    <Layout>
      <div className="dashboard-main">
        <div className="main-wrapper max-w-5xl p-6">
          <h1 className="text-xl font-bold">API Keys</h1>

          {/* wokring */}
          {Array.isArray(received)
            ? received.map((item: BinanceKey, idx: number) => (
                <div
                  key={item.id}
                  className={`bg-cyan-300 rounded-lg flex items-center p-5 gap-4 mt-5`}
                >
                  <p className="text-lg font-bold basis-5/12 flex items-center gap-4">
                    {item.key}
                  </p>

                  <div className="basis-2/12 text-right">
                    <span className="block text-xs">Value</span>
                    <span className="font-bold text-[16px]">
                      {item.secretKey}
                    </span>
                  </div>
                  <input
                    type="text"
                    onChange={(e) => {
                      const updatedKeys = [...keys];
                      updatedKeys[idx].newKey = e.target.value;
                      setKeys(updatedKeys);
                    }}
                    value={keys?.[idx]?.newKey}
                    placeholder="Update key"
                    className="block basis-2/12 rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  <button
                    // eslint-disable-next-line  @typescript-eslint/no-misused-promises

                    onClick={() => {
                      updateBinanceKey
                        ?.mutateAsync({
                          id: item?.id,
                          key: item?.key,
                          secretKey: keys?.[idx]?.newKey,
                        })
                        .then((res: any) => {
                          if (res) {
                            toast.success("Key updated successfully!");
                            const updatedKeys = [...keys];
                            updatedKeys[idx].newKey = "";
                            setKeys(updatedKeys);
                          }
                        })
                        .catch((err: any) => {
                          toast.error(err?.message || "Something went wrong");
                        });
                    }}
                    className="bg-cyan-800 basis-1/12 border border-cyan-300 rounded-md text-black-700 text-md font-bold transition px-8 py-2 sm:text-sm"
                    disabled={updateBinanceKey?.isLoading}
                  >
                    {updateBinanceKey?.isLoading ? (
                      <div
                        className="w-5 h-5 rounded-full animate-spin mx-auto
                      border-2 border-solid border-black-800 border-t-transparent"
                      ></div>
                    ) : (
                      "Update"
                    )}
                  </button>
                </div>
              ))
            : "No Bitfinix keys found"}
        </div>
      </div>
    </Layout>
  );
};

export default APIs;
